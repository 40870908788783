<template>
  <div>
    <h1 class="ma-2 ml-0">Lançamentos</h1>
    <v-card v-if="accounts.length">
      <v-card-title
        class="d-flex align-center justify-space-between flex-wrap py-sm-2 py-6"
      >
        <div class="row my-2 align-center">
          <v-select
            v-model="selectedAccount"
            :items="accounts"
            item-text="description"
            item-value="id"
            label="Selecionar Conta"
            :dense="dense"
            hide-details="auto"
            filled
            outlined
            @change="getCashMovimentAccounts()"
            class="col-sm-8 col-10"
          ></v-select>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                color="success"
                :content="filteredMoviments.length"
                :value="filterApply && filteredMoviments.length > 0"
                overlap
              >
                <!-- <v-btn icon :disabled="moviments.length == 0" @click="applyFilter()" v-bind="attrs" v-on="on" class="ma-1"> -->
                <v-btn
                  icon
                  @click="applyFilter()"
                  v-bind="attrs"
                  v-on="on"
                  class="ma-1"
                >
                  <v-icon :color="filterApply ? 'success' : 'secondary'">{{
                    icons.mdiFilter
                  }}</v-icon>
                </v-btn>
              </v-badge>
            </template>
            <span>{{ filterApply ? "Retirar Filtro" : "Aplicar Filtro" }}</span>
          </v-tooltip>
        </div>
        <div class="my-2" :class="{ 'w-full': isMobile }">
          <v-btn
            color="success"
            class="ma-sm-2 my-2 text-capitalize"
            :block="isMobile"
            @click="newCashMoviment('income')"
          >
            <v-icon>
              {{ icons.mdiArrowTopRightThick }}
            </v-icon>
            Entrada
          </v-btn>
          <v-btn
            color="error"
            class="ma-sm-2 my-2 text-capitalize"
            :block="isMobile"
            @click="newCashMoviment('outcome')"
          >
            <v-icon>
              {{ icons.mdiArrowBottomLeftThick }}
            </v-icon>
            Saída
          </v-btn>
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-show="selectedAccount"
                color="secondary"
                class="ma-sm-2 my-2 text-capitalize"
                :block="isMobile"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  {{ icons.mdiCog }}
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="openImportOFX(selectedAccount)">
                <v-list-item-title>
                  <v-icon size="20">
                    {{ icons.mdiUpload }}
                  </v-icon>
                  <span>Importar OFX</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-card-title>
      <v-data-table
        :items-per-page="25"
        show-select
        :headers="tableColumns"
        :items="moviments"
        :loading="loading"
        loading-text="Carregando Tabela..."
        @page-count="pageCount = $event"
        :footer-props="{
          itemsPerPageOptions: [25, 50, 100, -1],
          itemsPerPageText: 'Itens por pagina',
          itemsPerPageAllText: 'Todos',
          disablePagination: true,
          nextIcon: '',
          prevIcon: '',
          pageText: '',
        }"
      >
        <template v-slot:item.type="{ item }">
          <v-icon
            color="success"
            v-if="item.type === 'income'"
            class="d-flex justify-center"
          >
            {{ icons.mdiArrowTopRightThick }}
          </v-icon>
          <v-icon v-else color="error" class="d-flex justify-center">
            {{ icons.mdiArrowBottomLeftThick }}
          </v-icon>
        </template>

        <template v-slot:item.value="{ item }">
          <span
            :style="{ color: item.type == 'income' ? 'lightgreen' : 'tomato' }"
          >
            {{ item.value / 100 | moneyBr }}
          </span>
        </template>

        <template v-slot:item.description="{ item }">
          <span>
            {{ item.description | limiter }}
          </span>
        </template>

        <template v-slot:item.account_plan="{ item }">
          {{ item.account_plan.description }}
        </template>

        <template v-slot:item.status="{ item }">
          <v-chip color="success" v-if="item.status == 'processed'" small>
            Processado
          </v-chip>
          <v-chip color="error" v-if="item.status == 'cancelled'" small>
            Cancelado
          </v-chip>
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ new Date(item.created_at).toLocaleDateString("pt-br") }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="editCashMoviment(item)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Editar</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.status == 'processed'"
                @click="openAlertDelete(item)"
              >
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Excluir</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-else @click="restoreCashMoviment(item)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiRestore }}
                  </v-icon>
                  <span>Restabelecer</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:no-data>
          <nothing-component></nothing-component>
        </template>

        <template v-slot:footer>
          <v-pagination
            v-model="pagination.current"
            :length="pagination.total"
            @input="onPageChange"
          ></v-pagination>
        </template>
      </v-data-table>
      <CashMovimentForm
        ref="form"
        @onSubmit="getCashMovimentAccounts()"
      ></CashMovimentForm>
      <cash-moviments-filter-component
        ref="filterForm"
        :data="moviments"
        @filter="filter($event)"
      ></cash-moviments-filter-component>
      <ImportOFXModal ref="importModal"></ImportOFXModal>
    </v-card>
    <nothing-component v-if="!accounts.length && !loading">
      <v-row class="pa-4">
        <v-btn
          color="success"
          class="ma-sm-2 my-2 text-capitalize"
          :block="isMobile"
          @click="newCashMoviment('income')"
        >
          <v-icon>
            {{ icons.mdiArrowTopRightThick }}
          </v-icon>
          Nova Entrada
        </v-btn>
        <v-btn
          color="error"
          class="ma-sm-2 my-2 text-capitalize"
          :block="isMobile"
          @click="newCashMoviment('outcome')"
        >
          <v-icon>
            {{ icons.mdiArrowBottomLeftThick }}
          </v-icon>
          Nova Saída
        </v-btn>
      </v-row>
    </nothing-component>
    <alert-delete-template ref="alert" @delete="delCashMoviment($event)"></alert-delete-template>
    
    <div v-if="loading" class="d-flex justify-center">
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import {
  mdiArrowBottomLeftThick,
  mdiArrowTopRightThick,
  mdiCog,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiFilter,
  mdiUpload,
  mdiRestore,
} from "@mdi/js";
import Filter, { moneyBr } from "@/util/Filters";

import CashMovimentForm from "@/components/financial/CashMoviment/form/CashMovimentForm";
import CashMovimentsFilterComponent from "@/components/app/filters/cashMoviments/cashMovimentsFilterComponent.vue";
import ImportOFXModal from "@/components/financial/CashMoviment/form/importOFX";
import NothingComponent from "@/components/nothingComponent.vue";
import AlertDeleteTemplate from "@/components/app/alert/AlertDeleteTemplate.vue"

const formatador = Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  minimumFractionDigits: 2,
});

export default {
  components: {
    CashMovimentsFilterComponent,
    CashMovimentForm,
    ImportOFXModal,
    NothingComponent,
    AlertDeleteTemplate
  },

  props: {
    dense: {
      type: Boolean,
    },
  },

  data() {
    return {
      tableColumns: [
        { text: "", value: "type" },
        { text: "Descrição", value: "description" },
        { text: "Conciliação", value: "conciliation" },
        { text: "Status", value: "status" },
        { text: "Valor", value: "value" },
        { text: "Data", value: "transaction_date" }
      ],

      icons: {
        mdiCog,
        mdiArrowBottomLeftThick,
        mdiArrowTopRightThick,
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiDeleteOutline,
        mdiFilter,
        mdiUpload,
        mdiRestore,
      },

      loading: false,
      accounts: [],
      filterApply: false,
      moviments: [],
      filteredMoviments: [],
      selectedAccount: 0,
      pagination: {
        current: 1,
        total: 0
      },
      accountId: "id"
    };
  },

  created() {
    this.getCashMovimentAccounts();
  },

  computed: {
    movimentShow() {
      return this.filterApply ? this.filteredMoviments : this.moviments;
    },
  },

  methods: {
    onPageChange() {
      this.getCashMovimentTransactions(this.selectedAccount);
    },
    getCashMovimentAccounts() {
      this.loading = true;
      this.$http.$get("/account").then((response) => {
          this.accounts = response.data.data
          if (this.selectedAccount !== null) {
            this.getCashMovimentTransactions(this.selectedAccount);
            this.loading = false;
          }
          this.loading = false;
      });
    },

    getCashMovimentTransactions(id) {
      this.loading = true;
      this.$http
        .index('/account-transaction?page=' + this.pagination.current, { account_id: id, params:this.filteredMoviments})
        .then((response) => {
            this.moviments = response.data.data;
            this.pagination.current = response.data.current_page;
            this.pagination.total = response.data.last_page;
            this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    
    newCashMoviment(type) {
      this.$refs.form.open(null, type, this.selectedAccount.id);
    },

    editCashMoviment(item) {
      this.$refs.form.open(item.id, item.type, null);
    },

    openAlertDelete(item){
      this.$refs.alert.open(item.id);
    },

    delCashMoviment(item) {
      this.$http
        .destroy(`/account-transaction/${item}`)
        .then((res) => {
          this.getCashMovimentTransactions();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    openImportOFX(selectedAccount) {
      this.$refs.importModal.open(selectedAccount);
    },

    applyFilter() {
      if (this.filterApply) {
        this.filterApply = true;
      } else {
        this.$refs.filterForm.open();
      }
    },

    filter(filter) {
      this.filteredMoviments = filter
      this.getCashMovimentTransactions();
    },
  },
  mounted() {
    this.onPageChange();
  }
};
</script>

<style>
</style>