<template>
  <v-dialog v-model="dialog" persistent width="500">
    <v-card>
      <v-card-title class="headline"> Atenção </v-card-title>
      <v-card-text> Você realmente Deseja Excluir </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" outlined @click="close"> Cancelar </v-btn>

        <template>
          <v-btn color="error" @click="deleteMethod()"> Excluir </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    text: "",
  },
  data() {
    return {
      dialog: false,
      account:null
    };
  },

  methods: {
    open(row) {
      this.dialog = true;
      this.account = row
    },

    close() {
      this.dialog = false;
    },

    deleteMethod() {
      this.$emit("delete", this.account);

      this.close();
    },
  },
};
</script>