<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    origin="center center"
    persistent
    :fullscreen="isMobile"
  >
    <v-card>
      <v-card-title>
        <v-icon class="mr-1" :color="isIncome ? 'success' : 'error'">
          {{
            isIncome
              ? icons.mdiArrowTopRightThick
              : icons.mdiArrowBottomLeftThick
          }}
        </v-icon>
        {{ isUpdating ? "Editar" : "Cadastrar" }} Lançamento de
        {{ isIncome ? "Entrada" : "Saída" }}
      </v-card-title>
      <v-divider></v-divider>
      <div v-if="loading" class="d-flex justify-center">
        <v-progress-circular indeterminate color="secondary" class="ma-2" />
      </div>
      <v-form v-show="!loading" v-model="formValid" ref="form">
        <v-card-text class="mt-6">
          <div class="row col-xs">
            <div class="col">
              <v-text-field
                v-model="cashMovimentForm.description"
                :rules="[rules.required]"
                label="Descrição"
                outlined
                dense
                hide-details="auto"
              />
            </div>
          </div>
          <div class="row col-xs">
            <div class="col pb-2">
              <v-autocomplete
                v-model="cashMovimentForm.account_plan_id"
                item-text="description"
                item-value="id"
                :rules="[rules.required]"
                :items="accountPlans"
                outlined
                dense
                label="Plano de Contas"
              ></v-autocomplete>
            </div>
          </div>
          <div class="row col-xs">
            <div class="col-md py-0">
              <money-input
                ref="valueInputRef"
                label="Valor"
                v-model="cashMovimentForm.value"
                :rules="[rules.required, rules.nonZero]"
              ></money-input>
            </div>
            <div class="col-md py-0">
              <date-picker
                ref="createdPickerRef"
                v-model="cashMovimentForm.created_at"
              ></date-picker>
            </div>
          </div>
          <div v-if="!isIncome" class="row col-xs">
            <div class="col-md pt-8">
              <v-file-input
                v-model="cashMovimentForm.documentFile"
                label="Documento"
                outlined
                dense
              ></v-file-input>
            </div>
          </div>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" class="text-capitalize" @click="close"
          >Cancelar</v-btn
        >
        <v-btn class="text-capitalize" color="success" @click="submitForm">
          <v-icon>
            {{ icons.mdiContentSave }}
          </v-icon>
          Salvar Informações
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiArrowBottomLeftThick,
  mdiArrowTopRightThick,
  mdiContentSave,
} from "@mdi/js";

import datePicker from "@/components/app/ui/datePicker.vue";
import moneyInput from "@/components/app/ui/moneyInput.vue";
import formMixin from "@/util/mixins/formMixin";

export default {
  name: "CashMovimentForm",

  components: {
    moneyInput,
    datePicker,
  },

  mixins: [formMixin],

  data() {
    return {
      dialog: false,
      formValid: false,
      isUpdating: false,
      form: null,
      isIncome: true,
      dateMenu: false,
      loading: false,

      cashMovimentForm: {
        tenant_id: null,
        company_id: null,
        account_id: null,
        account_plan_id: null,
        origin: "system",
        type: null,
        description: null,
        account_plan: null,
        value: "0,00",
        balance: null,
        created_at: null,
        status: "processed",
        documentFile: null,
      },

      accountPlans: [],
      selectedAccountPlans: null,

      icons: {
        mdiArrowBottomLeftThick,
        mdiArrowTopRightThick,
        mdiContentSave,
      },
    };
  },

  watch: {
    dialog() {
      if (!this.dialog) {
        this.$refs.form.reset();
        this.$refs.valueInputRef.reset();
        this.$refs.createdPickerRef.reset();
        this.cashMovimentForm.updated_at = null;
        this.cashMovimentForm.account_plan = null;
        this.cashMovimentForm.tenant_id = null;
      }
    },
  },

  methods: {
    open(id = null, type, account_id) {
      this.dialog = true;

      this.isUpdating = id != null;
      this.cashMovimentForm.account_id = account_id;

      this.setTypeText(type);
      this.getAccountPlans();

      if (id) {
        this.isUpdating == true;

        this.setTypeText(type);
        this.getCashMovimentForm(id);
      }
    },

    close() {
      this.dialog = false;
    },

    getAccountPlans() {
      this.$http.$get("/account-plan").then((response) => {
        this.accountPlans = response.data;
      });
    },

    getCashMovimentForm(id) {
      this.loading = true;

      this.$http
        .$get(`/account-transaction/${id}`)
        .then((response) => {
          this.cashMovimentForm = response.data;
          this.$refs.valueInputRef.reset(this.cashMovimentForm.value);

          this.getPlanAccount();

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getPlanAccount() {
      this.$http
        .$get(`account-plan/${this.cashMovimentForm.account_plan_id}`)
        .then((response) => {
          this.cashMovimentForm.account_plan = response.data.description;
          this.loading = false;
        });
    },

    submitForm() {
      if (this.$refs.form.validate()) {
        if (this.isUpdating == true) {
          this.updateForm();
          return
        }

        this.storeForm();
      }
    },

    updateForm() {
      this.$http
        .$put(
          "/account-transaction/" + this.cashMovimentForm.id, this.cashMovimentForm
        )
        .then((response) => {
          if (response.status == 200) {
            this.$emit("onSubmit");
            this.close();
          }
        });
    },

    storeForm() {
      this.$http
        .$post("/account-transaction", this.cashMovimentForm)
        .then((response) => {
          this.$emit("onSubmit");
        });

      this.$emit("onSubmit");
      this.dialog = false;
    },

    setTypeText(type) {
      if (type == "income") {
        this.isIncome = true;
        this.cashMovimentForm.type = type;
        return;
      }
      this.isIncome = false;
      this.cashMovimentForm.type = type;
    },
  },
};
</script>