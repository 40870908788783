<template>
    <v-dialog v-model="dialog" max-width="1200px" origin="center center" persistent :fullscreen="isMobile">
        <v-card flat>
            <v-card-title >
                <v-icon class="mr-1">
                    {{ icons.mdiFileDocumentOutline }}
                </v-icon>
                Importar Arquivo OFX
            </v-card-title>
                <v-card-text>
                    <div class="row align-center">
                        <v-file-input
                            label="Selecione o documento..."
                            outlined dense
                            @change="processOFX($event)"
                            hide-details
                        ></v-file-input>
                        <v-spacer></v-spacer>
                        <span class="my-4 mx-8" :style="{'color': balance.value < 0 ? 'red' : 'green'}">
                            <b>Saldo: </b> 
                            {{ balance.value | moneyBr }}
                        </span>
                    </div>
                    <div class="mt-4">
                        <v-divider class="mb-6"></v-divider>
                        <v-data-table
                            :headers="tableColumns"
                            :items="processedFile"
                            :loading="loading"
                            loading-text="Carregando Arquivo..."
                            :footer-props="{
                                itemsPerPageOptions: [25,50,100,-1],
                                itemsPerPageText: 'Itens por pagina',
                                itemsPerPageAllText: 'Todos',
                                disablePagination: true,
                                nextIcon: '',
                                prevIcon: '',
                                pageText: ''
                            }"   
                        >
                            <template v-slot:no-data >
                                <nothingComponent/>
                            </template>

                            <template v-slot:item.type="{item}" >
                                <v-icon color="success" v-if="item.type === 'income'" class="d-flex justify-center">
                                    {{ icons.mdiArrowTopRightThick }}
                                </v-icon>
                                <v-icon v-else color="error" class="d-flex justify-center">
                                    {{ icons.mdiArrowBottomLeftThick }}
                                </v-icon>
                            </template>

                            <template v-slot:item.value="{item}">
                                <span :style="{color: item.value < 0 ? 'red' : 'green'}">
                                    {{ item.value / 100 | moneyBr }}
                                </span>
                            </template>

                            <template v-slot:item.emmited_at="{item}" >
                                {{ new Date(item.date).toLocaleDateString("pt-br") }}
                            </template>

                            <template v-slot:item.conciliation="{item}">
                                <v-text-field
                                v-model="item.conciliation"
                                :key="item.id"
                                label="Insira uma descrição..."
                                outlined
                                dense
                                hide-details="auto"
                                />
                            </template>

                            <!-- ="{ item }" -->
                            <template v-slot:item.actions>
                                <v-menu bottom left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>
                    </div>
                </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="close" class="text-capitalize">
                    Cancelar
                </v-btn>
                <v-btn color="success" @click="submitOFX()" :disabled="!processedFile" class="text-capitalize">
                    <v-icon>
                        {{ icons.mdiUpload }}
                    </v-icon>
                    Importar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mdiArrowBottomLeftThick, mdiArrowTopRightThick, mdiCog, mdiDotsVertical, mdiFileDocumentOutline, mdiDeleteOutline, mdiMagnify, mdiUpload } from '@mdi/js';
import Filter from '@/util/Filters'
import nothingComponent from '@/components/nothingComponent.vue';

export default {
    name: 'ImportOFXModal',

    components: { nothingComponent },
    
    data(){
        return {
            dialog: false,
            loading: false,
            file: {},
            processedFile: [],
            bank: '',
            selectedAccountId: null,

            icons: {
                mdiCog,
                mdiArrowBottomLeftThick,
                mdiArrowTopRightThick,
                mdiDotsVertical,
                mdiFileDocumentOutline,
                mdiDeleteOutline,
                mdiMagnify,
                mdiUpload
            },

            tableColumns: [
                {text: 'Titulo', value:'title'},
                {text: 'Data de Emissão', value:'transaction_date'},
                {text: 'Valor', value:'value'},
                {text: 'Conciliação', value:'conciliation', editable: true}
            ],
        }
    },

    computed: {
        balance(){
            if (this.processedFile) {
                let balance = 0
                this.processedFile.forEach(element => {
                    balance = element.type === 'income' ? balance + element.value : balance - element.value
                })
                return {value:Math.abs(balance) / 100, negative: 0 > balance}
            }else{
                return 0
            }
        }
    },

    methods:{
        open(selectedAccount){
            this.selectedAccountId = selectedAccount
            this.dialog = true
        },

        processOFX(event){
            this.processedFile = []
            if (event) {
                this.file = event;
                this.loading = true;

                let formData = new FormData();
                formData.append("file", this.file);
                formData.append("account_id", this.selectedAccountId);

                this.$http.$post("/extract-load", formData).then((response) => {
                    for (var result in response) {
                        this.processedFile.push(response[result]);
                        this.bank = response.bank;
                        this.loading = false;
                    }
                });
            }
        },

        submitOFX(){
            this.$http.$post("/extract-import", { 
                data: this.processedFile,
                account_id: this.selectedAccountId 
            });
            this.dialog = false
        },
        
        close(){
            this.dialog = false
        },
    }

}
</script>

<style>

</style>